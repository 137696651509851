import { useContext, useEffect, useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Box } from '@chakra-ui/react'
import {
	analyticsSend,
	Layout,
	ControlPanel
} from '@perchinteractiveinc/react-template-lb'

import {
	CollectionCarousel,
	Products,
	LangTabs,
	SensingWrapper
} from 'components'
import { getCollectionsLocale, useEngCollections, useEngProducts } from 'utils'
import { i18nCollections, Collection, AttractPageQuery } from 'interfaces'
import { LangContext } from 'context'

import * as styles from './index.module.scss'
import '../styles/global.css'

const IndexPage = () => {
	const data: AttractPageQuery = useStaticQuery(graphql`
		query {
			collections: allStrapiCollection(
				sort: { fields: collection_uid, order: ASC }
			) {
				nodes {
					locale
					name
					collection_uid
					product_images {
						localFile {
							childImageSharp {
								gatsbyImageData
							}
						}
					}
					products {
						name
						sensing_id
						product_uid
						description {
							data {
								description
							}
						}
						headline
						how_to {
							step_number
							description
						}
						id
						reviews {
							title
							stars
							description
						}
						video {
							url
						}
						image {
							localFile {
								childImageSharp {
									gatsbyImageData
								}
							}
						}
						product_icons {
							localFile {
								childImageSharp {
									gatsbyImageData
								}
							}
						}
						featured_review
						featured_stars
					}
				}
			}
			attractPage: strapiAttractPage {
				header_video {
					url
				}
			}
		}
	`)

	const { lang } = useContext(LangContext)
	const collectionsData: i18nCollections = getCollectionsLocale(data)

	const [collections, setCollections] = useState<Collection[]>(
		collectionsData[lang]
	)

	const [collectionIndex, setCollectionIndex] = useState<number>(0)

	const [activeProductIndex, setActiveProductIndex] = useState<number>(0)
	const allEngProducts = useEngProducts(collectionsData)
	const engCollections = useEngCollections(collectionsData)

	const onCollectionCarouselChange = (index: number) => {
		setCollectionIndex(index)
		setActiveProductIndex(0)
		if (collections[index]) {
			const engCollection = engCollections.find(
				c => c.collection_uid === collections[index].collection_uid
			)
			if (engCollection) {
				analyticsSend({
					type: 'track',
					button: 'Carousel - Attract',
					product: `Collection: ${engCollection.name}`
				})
			}
		}
	}

	const onActiveProductChange = (index: number) => {
		setActiveProductIndex(index)
	}

	useEffect(() => {
		setCollections(collectionsData[lang])
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [lang, setCollections])

	return (
		<SensingWrapper products={allEngProducts}>
			<ControlPanel />

			<Layout>
				<div
					className={`${styles.pageContainer} ${lang === 'he' ? 'hebrew' : ''}`}
				>
					<Box>
						<Box className={styles.header}>
							<video autoPlay muted loop>
								<source src={data.attractPage.header_video.url} />
							</video>
						</Box>
						<Box className={styles.smallCarousel}>
							{collections && (
								<CollectionCarousel
									items={collections}
									onCarouselChange={onCollectionCarouselChange}
									currentIndex={collectionIndex}
									analytics={{ product: 'Attract' }}
								/>
							)}
						</Box>
						<Box className={styles.hairQuiz} bgColor='whiteAmika'>
							<Box bgColor='orange' h='30px'></Box>
						</Box>
						<Box className={styles.content}>
							<LangTabs />
							{collections && (
								<Products
									key={collectionIndex}
									lang={lang}
									featuredProducts={collections[collectionIndex].products}
									activeProductIndex={activeProductIndex}
									onActiveProductChange={onActiveProductChange}
									engProducts={allEngProducts}
								/>
							)}
						</Box>
					</Box>
				</div>
			</Layout>
		</SensingWrapper>
	)
}

export default IndexPage
