// extracted by mini-css-extract-plugin
export var carouselImg = "index-module--carousel-img--ThZOD";
export var content = "index-module--content--7WlJI";
export var contentHeader = "index-module--content-header--nZEML";
export var contentVideo = "index-module--content-video--bZuaU";
export var description = "index-module--description--3Gg0J";
export var hairQuiz = "index-module--hair-quiz--zmfOZ";
export var header = "index-module--header--IyPau";
export var headerTitle = "index-module--header-title--6sLAh";
export var mainProducts = "index-module--main-products--A6p7T";
export var mainProductsCarousel = "index-module--main-products-carousel--XJnjD";
export var pageContainer = "index-module--page-container--1oKzp";
export var productDescription = "index-module--product-description--7yGTe";
export var productTitle = "index-module--product-title--h+IUD";
export var resume = "index-module--resume--mNky6";
export var score = "index-module--score--ZqIYr";
export var smallCarousel = "index-module--small-carousel--+L7SM";
export var video = "index-module--video--KItaY";